import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';

export const HeaderWrapper = styled.div`
  /* ${tw`px-4 py-2 pt-6`} */
  padding: 1rem 1rem 0rem;
  width: 100%;
  background-color: transparent;
`;

export const RestaurantName = styled.h1`
  /* ${tw`mb-3`}     */
  font-size: 1.5rem;
  font-weight: 500;
`;
