import React from 'react';
import { SearchIconSmall } from '../AllSvgIcon.js';
import {
  ChangeLocaleWrapper,
  DeliveryType,
  LocaleItem
} from './ChangeLocale.style';
import { useStores } from 'hooks/useStores.js';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { withTranslation } from 'i18n.js';
import { BiDownArrow } from 'react-icons/bi';
import { FiChevronDown } from 'react-icons/fi';

const allLanguages = [
  {
    language: 'en',
    dir: 'ltr',
    label: 'English',
    locale: 'English'
  },
  {
    language: 'ar',
    dir: 'rtl',
    label: 'Arabic',
    locale: 'عربي'
  },
  {
    language: 'cn',
    dir: 'ltr',
    label: 'Chinese',
    locale: '中国人'
  },
  {
    language: 'ru',
    dir: 'ltr',
    label: 'Russian',
    locale: 'Русский'
  }
];

const ChangeLocale = observer(({ t, i18n }) => {
  const { authStore, cartStore } = useStores();

  const [opened, setOpened] = React.useState(false);
  const [curLanguage, setCurLanguage] = React.useState(null)
  const [availableLanguages, setAvailableLanguages] = React.useState([])

  const changeLanguage = (newLang) => {
    // const newLang = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLang.lang);
    setCurLanguage(newLang)
    authStore.setLang(newLang.lang);
    document.documentElement.dir = i18n.dir(newLang.lang);
    document.documentElement.lang = newLang;
    setOpened(false);
  };

  React.useEffect(() => {
    // setCurLanguage(cartStore.currentRestaurant.languages.filter(lng => lng.lang == i18n.language)[0])
  }, [i18n, cartStore.currentRestaurant]);
  
  React.useEffect(() => {
    if (cartStore.currentRestaurant && cartStore.currentRestaurant.languages) {
      const l = cartStore.currentRestaurant.languages.filter(lng => lng.lang == i18n.language);

      if (l && l[0]) {
        setCurLanguage(cartStore.currentRestaurant.languages.filter(lng => lng.lang == i18n.language)[0])
      }
      else {
        changeLanguage(cartStore.currentRestaurant.languages[0]);
        // setCurLanguage(cartStore.currentRestaurant.languages[0]);
      }
    }
  }, [cartStore.currentRestaurant, i18n]);

  if (!curLanguage || !curLanguage.label) return false;

  return (
    <div className="flex items-center relative">
      <a className="px-3 py-0 mx-1 bg-primary-lighter flex items-center rounded border-0 cursor-pointer" onClick={() => setOpened(!opened)}>
        {curLanguage.label} 
        <span className='pl-1'>
          {/* <BiDownArrow size="1rem" /> */}
          <FiChevronDown size="1rem" />
        </span>
      </a>
      {opened ? (
      <div className='fixed left-0 right-0 bottom-0 top-0 bg-white z-50 opacity-20' onClick={() => setOpened(false)}></div>
      ): null}
      {opened ? (
        <div className="absolute flex flex-col top-0 mt-6 rounded border border-primary-600 bg-primary-lighter z-50">
          {cartStore.currentRestaurant.languages.map((lng) => (
            <a className="px-3 py-1 mx-1 inline-block cursor-pointer border-b border-primary-400 last:border-0" onClick={() => changeLanguage(lng)}>
              {lng.label}
            </a>
          ))}
        </div>
      ) : null}
    </div>
  );

  return (
    <ChangeLocaleWrapper>
      <LocaleItem onClick={() => changeLanguage()}>
        {i18n.language === 'ar' ? 'English' : 'العربية'}
      </LocaleItem>
    </ChangeLocaleWrapper>
  );
});

export default withTranslation('common')(ChangeLocale);
