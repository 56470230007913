import React from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import Areas from 'containers/Areas/Areas';
import Header from 'components/Header/Header';
import DeliverySelection from 'components/DeliverySelection/DeliverySelection';
import { useStores } from 'hooks/useStores';
import Outlets from 'containers/Outlets/Outlets';
import { observer } from 'mobx-react';
import AreasContainer from 'containers/Areas/AreasContainer';
import Spinner from 'components/Spinner/Spinner';
import ChangeLocale from 'components/ChangeLocale/ChangeLocale';
import { withTranslation } from 'i18n';
import { toJS } from 'mobx';
import TestMode from 'components/TestMode/TestMode';
import agent from 'utils/agent';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { Fragment } from 'react';
import SEO from 'components/SEO/SEO';
import AppLayout from 'containers/AppLayout/AppLayout';
import { Business } from 'utils/_agent-business';

const Restaurant = observer(({ i18n, name, data }) => {
  const router = useRouter();
  const { restaurant } = router.query;
  const { cartStore } = useStores();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // cartStore.setArea({});
    setLoading(false);
    console.log('RESTAURANT CLIENT::', restaurant);
    // console.log('DATA FROM SERVER:::', data);

    if (data && data.id) {
      cartStore.setRestaurant(data);

      cookie.set('et-slug', data.url, {expires: 1});

      if (cartStore.currentOutlet && cartStore.currentOutlet.id) {
        if (cartStore.orderType) {
          if (data.domain)
          router.replace(`/${cartStore.orderType}/${cartStore.currentOutlet.url}`
          );
          else
          router.replace(
            `/[restaurant]/[orderType]/[store]`,
            `/${restaurant}/${cartStore.orderType}/${cartStore.currentOutlet.url}`,
            {
              // shallow: true
            }
          );
        } else {
          if (data.has_delivery) {
            if (data.domain)
            router.replace(`/delivery/${cartStore.currentOutlet.url}`);
            else
            router.replace(
              `/[restaurant]/[orderType]/[store]`,
              `/${restaurant}/delivery/${cartStore.currentOutlet.url}`,
              {
                // shallow: true
              }
            );
          } else if (data.has_pickup) {
            if (data.domain)
            router.replace(`/pickup/${cartStore.currentOutlet.url}`);
            else
            router.replace(
              `/[restaurant]/[orderType]/[store]`,
              `/${restaurant}/pickup/${cartStore.currentOutlet.url}`,
              {
                // shallow: true
              }
            );
          }
        }
      } else {
        if (data.domain) {
          console.log('HAS DOMAIN: ', data.domain);
          router.replace(`/menu`);
        }
        else {
          console.log('NO DOMAIN: ');

          router.replace(`/[restaurant]/menu`, `/${restaurant}/menu`, {
            // shallow: true
          });
        }
      }
    }

    if (!cartStore.orderType) {
      // console.log('Setting Delivery');
      cartStore.setOrderType('delivery');
    }

    if (
      cartStore.currentRestaurant &&
      !cartStore.currentRestaurant.has_delivery
    ) {
      // console.log('Setting Pickup');
      cartStore.setOrderType('pickup');
    }
  }, []);

  return (
    <>
      <SEO data={data} />
      <AppLayout>
        <Spinner />
      </AppLayout>
    </>
  );
  // return null;

});

// export async function getStaticPaths() {
//   return {
//     paths: [], //indicates that no page needs be created at build time
//     fallback: 'blocking' //indicates the type of fallback
// }
// }

export async function getServerSideProps({ params, res, req }) {
  const { restaurant } = params;
  // const res = await fetch(`https://...`)
  let data = {};
  let r = {};

  const domain = req.headers.host;

  console.log('RESTAURANT SERVER::', restaurant);

  // await Business.all(`/business/${restaurant}`)
  //   .then((response) => {
  //     console.log('Restaurant Loaded 0', response);
  //     if (response.id) {
  //       data = response;
  //       r = {
  //         props: { data } // will be passed to the page component as props
  //       };
  //     } else {
  //       r = {
  //         notFound: true
  //       };
  //     }
  //   })
  //   .catch((err) => {
  //     console.log('ERROR TYPE:::', err.response.status);
  //     //         // if (err.response) {
  //     if (err.response && err.response.status && err.response.status == 404) {
  //       // router.push('/404');
  //       res.statusCode = 404;
  //     }

  //     r = {
  //       notFound: true
  //     };
  //   });

  await Business.all(`/business/${restaurant}`)
    .then((response) => {
      console.log('Restaurant Loaded 1', response);
      if (response.id) {
        data = response;
        r = {
          props: { data } // will be passed to the page component as props
        };

        // r = {
        //   redirect: {
        //     permanent: false,
        //     destination: `menu`,
        //   },
        //   props:{},
        // }

        if (data.domain && data.domain !== domain) {
          console.log('DIFF DOMAIN 1', `http://${data.domain}`)
          r = {
            redirect: {
              permanent: false,
              destination: `http://${data.domain}`,
            },
            props:{},
          }
        }
      } else {
        r = {
          notFound: true
        };
      }
    })
    .catch((err) => {
      console.log('ERROR TYPE::: 2', err.response.status);
      //         // if (err.response) {
      if (err.response && err.response.status && err.response.status == 404) {
        // router.push('/404');
        res.statusCode = 404;
      }

      r = {
        notFound: true
      };
    });

  return r;
}

export default withTranslation('common')(Restaurant);
