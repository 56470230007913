import React from 'react';
import { SearchIconSmall } from '../AllSvgIcon.js';
import Link from 'next/link';
import {
  DeliverySelectionWrapper,
  DeliveryType
} from './DeliverySelection.style';
import { useStores } from 'hooks/useStores.js';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { withTranslation } from 'i18n.js';
const DeliverySelection = observer(({ t, i18n }) => {
  const { cartStore } = useStores();
  const router = useRouter();
  const { restaurant, store, orderType } = router.query;

  const changeOrderType = (type) => {
    cartStore.setOrderType(type);
    if (
      cartStore.currentRestaurant &&
      cartStore.currentRestaurant.domain &&
      cartStore.currentRestaurant.domain.length > 2
    ) {
      router.replace(`/${type}`);
    } else {
      router.replace(`/[restaurant]/[orderType]`, `/${restaurant}/${type}`);
    }
  };
  return (
    <DeliverySelectionWrapper>
      {cartStore.currentRestaurant &&
      cartStore.currentRestaurant.has_delivery ? (
        // <Link href={`/${restaurant}/delivery`}>
        <DeliveryType
          className={orderType == 'delivery' ? 'active' : ''}
          lang={i18n.language}
          onClick={() => changeOrderType('delivery')}
        >
          {t('delivery')}
        </DeliveryType>
      ) : // </Link>
      null}
      {cartStore.currentRestaurant && cartStore.currentRestaurant.has_pickup ? (
        <DeliveryType
          className={orderType == 'pickup' ? 'active' : ''}
          lang={i18n.language}
          onClick={() => changeOrderType('pickup')}
        >
          {t('pickup')}
        </DeliveryType>
      ) : null}
    </DeliverySelectionWrapper>
  );
});

export default withTranslation('common')(DeliverySelection);
