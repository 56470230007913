import { useStores } from 'hooks/useStores';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import React from 'react';

const SEO = ({ data }) => {
  const { cartStore } = useStores();
  const router = useRouter();
  const [url, setUrl] = React.useState(null)

  React.useEffect(() => {
    // console.log('DATA::', data);
    let baseUrl = `${process.env.NEXT_PUBLIC_APP_URL}`;
    if (typeof window !== 'undefined') {
        const host = window.location.host;
        baseUrl = `https://${host}`;
    }
    // console.log('BASEURL:', baseUrl)
    // console.log('DATA::', data);
    // console.log('URL::', router.pathname, router.asPath);
    setUrl(`${router.pathname}${router.asPath}`);
  }, [data]);

  if (!data || !data.name || !data.name.en) return null;

  return (
    <NextSeo
      title={
        data.seo && data.seo.meta_title
          ? data.seo.meta_title
          : `Order from ${data.name.en} | Eatroot`
      }
      description={
        data.seo && data.seo.meta_description
          ? data.seo.meta_description
          : `Order direct from ${
              data.name && data.name.en
            } via Eatroot and enjoy best offers`
      }
    //   canonical="https://order.eatroot.io/"
      openGraph={{
        url: `${url}`,
        title:
          data.seo && data.seo.og_title
            ? data.seo.og_title
            : `Order from ${data.name.en} | Eatroot`,
        description:
          data.seo && data.seo.og_description
            ? data.seo.og_description
            : `Order direct from ${
                data.name && data.name.en
              } via Eatroot and enjoy best offers`,
        images: [
          // {
          //   url: 'https://www.example.ie/og-image-01.jpg',
          //   width: 800,
          //   height: 600,
          //   alt: 'Og Image Alt',
          //   type: 'image/jpeg'
          // },
          // {
          //   url: 'https://www.example.ie/og-image-02.jpg',
          //   width: 900,
          //   height: 800,
          //   alt: 'Og Image Alt Second',
          //   type: 'image/jpeg'
          // },
          {
            url:
              data.seo && data.seo.og_image
                ? data.seo.og_image
                : data.media
                ? data.media.banner
                  ? data.media.banner
                  : data.media.logo
                  ? data.media.logo
                  : ''
                : ''
          }
          // { url: 'https://www.example.ie/og-image-04.jpg' }
        ],
        site_name:
          data.seo && data.seo.og_site_name
            ? data.seo.og_site_name
            : data.name.en
      }}
      // twitter={{
      //   handle: '@handle',
      //   site: '@site',
      //   cardType: 'summary_large_image'
      // }}
    />
  );
};

export default SEO;
