import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';

export const DeliverySelectionWrapper = styled.div`
  ${tw`flex items-center`}
  /* width: 100%; */
  background-color: transparent;
`;

export const DeliveryType = styled.button`
  ${tw`px-10 py-1 w-1/2 mx-0 inline-block border border-gray-400 cursor-pointer`}
  &.active {
    /* ${tw`bg-primary-lighter text-primary`} */
    ${tw`bg-primary border-primary text-buttontext`}
  }
  &:first-child {
    ${(props) =>
      props.lang == 'ar' ? tw`rounded-r ` : tw`rounded-l `};
  }
  &:last-child {
    ${(props) =>
      props.lang == 'ar' ? tw`rounded-l ` : tw`rounded-r `};
  }
`;
export const DeliveryTypeSmall = styled.button`
  ${tw`px-3 py-1 mx-0 inline-block border border-gray-400 cursor-pointer text-xs`}
  &.active {
    ${tw`bg-primary border-primary text-buttontext`}
  }
  &:first-child {
    ${(props) =>
      props.lang == 'ar' ? tw`rounded-r ` : tw`rounded-l `};
  }
  &:last-child {
    ${(props) =>
      props.lang == 'ar' ? tw`rounded-l ` : tw`rounded-r `};
  }
`;
