import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';

export const ChangeLocaleWrapper = styled.div`
  ${tw`flex items-center`}
  /* width: 100%; */
  background-color: transparent;
`;

export const LocaleItem = styled.button`
  ${tw`px-3 py-0 mx-1 bg-primary-lighter inline-block rounded border-0 cursor-pointer`}
  &.active {
    ${tw`bg-primary text-buttontext`}
  }
  &:last-child {
    /* ${tw`mr-0`} */
  }
`;
